/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import {  SafeAreaView,  ScrollView,  StatusBar,  StyleSheet,  Text,  useColorScheme,  View, Button} from 'react-native';



const App =  () => {
  
  return (
    <View>
      <Text style={{fontSize:18}}>what a day is this </Text>
      <Button />
      <text style={{ fontSize: 18 }}>what a day is this</text>
      <Text style={{fontSize: 30,fontWeight: 550,color: 'black',marginBottom: 10,textAlign: "center",marginTop: 10}}>Join the Game Conversation</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: '600',
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: '400',
  },
  highlight: {
    fontWeight: '700',
  },
});

export default App;
